import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4b59419f6570b0f9b1cdc4ee0ea2ce4d/bf173/joshua-caroll.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4b59419f6570b0f9b1cdc4ee0ea2ce4d/8ac56/joshua-caroll.webp 240w", "/static/4b59419f6570b0f9b1cdc4ee0ea2ce4d/f3a60/joshua-caroll.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4b59419f6570b0f9b1cdc4ee0ea2ce4d/09b79/joshua-caroll.jpg 240w", "/static/4b59419f6570b0f9b1cdc4ee0ea2ce4d/bf173/joshua-caroll.jpg 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4b59419f6570b0f9b1cdc4ee0ea2ce4d/bf173/joshua-caroll.jpg",
              "alt": "Joshua Caroll",
              "title": "Joshua Caroll",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Rank`}</h2>
    <ul>
      <li parentName="ul">{`Kendo Renshi 7th Dan`}</li>
    </ul>
    <h2>{`Background`}</h2>
    <ul>
      <li parentName="ul">{`A member of All US Kendo Federation.`}</li>
      <li parentName="ul">{`A member of All Eastern US Kendo Federation.`}</li>
      <li parentName="ul">{`1989 – Started kendo at Marianopolis College in Montreal, Canada.`}</li>
      <li parentName="ul">{`1997 – 10th World Kendo Tournament, US Representative of Individual division.`}</li>
      <li parentName="ul">{`B.A. in Humanistic Studies from McGill University in Montreal, Canada.`}</li>
    </ul>
    <h2>{`Tournament Results`}</h2>
    <h3>{`Individual`}</h3>
    <ul>
      <li parentName="ul">{`2015 - All Eastern US Tournament, 3 dan and above - 1st Place`}</li>
      <li parentName="ul">{`2015 - EUSKF 8th Annual Goodwill Invitation Kendo Tournament, 3 dan and above - 2nd Place`}</li>
      <li parentName="ul">{`2014 - Goyokai Kendo Tournament, Men’s 4 dan and above - 3rd Place`}</li>
      <li parentName="ul">{`2013 - All Eastern US Tournament, Men’s 4 dan and above - 2nd Place`}</li>
      <li parentName="ul">{`2011 - All Eastern US Tournament, 4 dan and above - 3rd Place`}</li>
      <li parentName="ul">{`2006 - All Eastern US Tournament, 4 dan and above - 3rd Place`}</li>
    </ul>
    <h3>{`Team`}</h3>
    <ul>
      <li parentName="ul">{`2016 - NY City Kendo Club 40th Anniv. Tournament, Men’s Team - 2nd Place`}</li>
      <li parentName="ul">{`2016 - Detroit Open Kendo Tournament - 3rd Place`}</li>
      <li parentName="ul">{`2015 - Garden State Kendo Alliance Tournament - 1st Place`}</li>
      <li parentName="ul">{`2015 - Goyokai Kendo Tournament - 1st Place`}</li>
      <li parentName="ul">{`2014 - Shidogakuin 30th Anniversary Kendo Tournament - 3rd Place`}</li>
      <li parentName="ul">{`2011/2014/2015/2016 - All Eastern US Tournament - 2nd Place`}</li>
      <li parentName="ul">{`2012 - Goyokai Kendo Tournament - 2nd Place`}</li>
      <li parentName="ul">{`2011 - All US Kendo Championship, AEUSKF Team - 2nd Place`}</li>
      <li parentName="ul">{`2011/2014 - Goyokai Kendo Tournament - 3rd Place`}</li>
      <li parentName="ul">{`2006 - NYC Kendo Club 30th Anniversary Kendo Tournament - 1st Place`}</li>
      <li parentName="ul">{`2006 - The Fifth Longhorn Invitational Team Kendo Taikai - 3rd Place`}</li>
      <li parentName="ul">{`2011/2014 – A member of All Eastern US Kendo Federation Men’s Team`}</li>
    </ul>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      